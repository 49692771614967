<template>
    <div class="navbar-container d-flex content align-items-center">
        <!-- Nav Menu Toggler -->
        <ul class="nav navbar-nav d-xl-none">
            <li class="nav-item">
                <b-link class="nav-link" @click="toggleVerticalMenuActive">
                    <feather-icon icon="MenuIcon" size="21" />
                </b-link>
            </li>
        </ul>

        <!-- Left Col -->
        <div
            class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
        >
            <!-- <dark-Toggler class="d-none d-lg-block" /> -->
        </div>

        <b-navbar-nav class="nav align-items-center ml-auto">
            <b-nav-item-dropdown
                right
                toggle-class="d-flex align-items-center dropdown-user-link"
                class="dropdown-user"
            >
                <template #button-content>
                    <div class="d-sm-flex d-none user-nav">
                        <p class="user-name mb-0">
                            Welcome,
                            <span class="font-weight-bolder"
                                >{{ name }} 🙂</span
                            >
                        </p>
                        <!-- <span class="user-status">Admin</span> -->
                    </div>
                    <b-avatar
                        v-if="!url"
                        size="40"
                        variant="light-primary"
                        :text="nameAvatar"
                    />
                    <b-avatar
                        v-else
                        size="40"
                        variant="light-primary"
                        :text="nameAvatar"
                        :src="url"
                    />
                </template>

                <!-- <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Account</span>
        </b-dropdown-item> -->

                <!-- <b-dropdown-divider /> -->

                <b-dropdown-item
                    link-class="d-flex align-items-center"
                    @click="logout()"
                >
                    <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
                    <span>Logout</span>
                </b-dropdown-item>
            </b-nav-item-dropdown>
        </b-navbar-nav>
    </div>
</template>

<script>
import {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
} from "bootstrap-vue"
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue"
import Auth from "@/api/services/Auth.js"

export default {
    components: {
        BLink,
        BNavbarNav,
        BNavItemDropdown,
        BDropdownItem,
        BDropdownDivider,
        BAvatar,

        // Navbar Components
        DarkToggler,
    },
    props: {
        toggleVerticalMenuActive: {
            type: Function,
            default: () => {},
        },
    },
    data() {
        return {
            name: "",
            nameAvatar: "",
            url: "",
        }
    },
    created() {
        const first_name = this.$store.state.portal.profile.first_name || ""
        const last_name = this.$store.state.portal.profile.last_name || ""
        const urlFromStore = this.$store.state.portal.profile.employee

        this.name = `${first_name} ${last_name}`
        this.nameAvatar = `${first_name ? first_name[0] : last_name[1]}
      ${last_name ? last_name[0] : first_name[1]}`
        this.url = urlFromStore ? urlFromStore.avatar : ""
    },
    methods: {
        loadData() {
            this.$loading(true)
            Auth.getUsers(this.$route.params.id).then((response) => {
                this.user = response.data
                this.$loading(false)
            })
        },
        logout() {
            Auth.logout()
        },
    },
}
</script>
